#projects {
    padding: 70px 10% 70px 10%;
    text-align: center;
    background-color: rgb(245, 245, 245);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#projects__intro {
    margin-bottom: 30px;
}

#projects h3 {
    margin-top: 50px;
    font-size: 2em;
    color: black;
}

.carousel {
    height: auto;
    border-radius: 10px;
    max-width: 1000px;
    box-shadow:
    0.3px 0.3px 2.2px rgba(0, 0, 0, 0.042),
    0.7px 0.7px 5.3px rgba(0, 0, 0, 0.061),
    1.3px 1.3px 10px rgba(0, 0, 0, 0.075),
    2.2px 2.2px 17.9px rgba(0, 0, 0, 0.089),
    4.2px 4.2px 33.4px rgba(0, 0, 0, 0.108),
    10px 10px 80px rgba(0, 0, 0, 0.15)
  ;
}

.carousel-image {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    transition: all 200ms;
}

.carousel-logo {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0 10px 0 10px;
    position: absolute;
    padding: 10px;
    bottom: 0px;
    left: 0px;
    transition: 200ms;
}


.carousel-skills {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 200ms;
}

.carousel-skills li {
    background-color: rgb(255, 100, 100);
    color: white;
    font-size: 1.2em;
    display: inline-block;
    padding: 10px;
    border-radius: 5px;
    box-shadow:
        1.4px 1.4px 2.2px rgba(0, 0, 0, 0.02),
        3.3px 3.3px 5.3px rgba(0, 0, 0, 0.028),
        6.3px 6.3px 10px rgba(0, 0, 0, 0.035),
        11.2px 11.2px 17.9px rgba(0, 0, 0, 0.042),
        20.9px 20.9px 33.4px rgba(0, 0, 0, 0.05),
        50px 50px 80px rgba(0, 0, 0, 0.07);
    margin: 5px;
    text-align: center;
    transition: 200ms;
}

@media screen and (max-width: 700px) {

    .carousel-skills li {
        font-size: 1em;
        padding: 5px;
    }
    
}

@media screen and (max-width: 500px) {

    #projects {
        padding: 70px 5% 70px 5%;
    }

    .carousel-skills {
        display: none;

    }

}
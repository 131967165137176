* {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 400;
}

body {
  margin: 0;
  min-width: 250px;
}

h1,
h2,
h3,
h4,
ul,
p {
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 3em;
  font-weight: 500;
  color: rgb(255, 100, 100);
  margin-bottom: 10px;
}

h3 {
  font-size: 1.5em;
  font-weight: 500;
  color: rgb(255, 100, 100);
  margin-bottom: 10px;
}

p {
  font-size: 1.2em;
}


@media (max-width: 700px) {
  h2 {
      font-size: 2.5em;
  }

}

@media (max-width: 500px) {
  h2 {
      font-size: 2em;
  }

}

@media (max-width: 300px) {
  h2 {
      font-size: 1.5em;
  }

}
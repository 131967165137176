#contact {
    background-color: rgb(230, 230, 230);
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px;
}

#contact h1 {
    margin-bottom: 30px;
}

#contact form {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 600px;
    box-shadow:
    2.8px 2.8px 2.2px rgba(0, 0, 0, 0.014),
    6.7px 6.7px 5.3px rgba(0, 0, 0, 0.02),
    12.5px 12.5px 10px rgba(0, 0, 0, 0.025),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.03),
    41.8px 41.8px 33.4px rgba(0, 0, 0, 0.036),
    100px 100px 80px rgba(0, 0, 0, 0.05)
  ;
}

#contact label {
    margin: 10px;
}

#contact input,
#contact textarea {
    padding: 20px;
    border: none;
    border-radius: 10px;
    background-color: rgb(248, 248, 248);
    font-weight: 600;
    outline: none;
}

#contact textarea {
    resize: vertical;
    max-height: 500px;
}

#contact button {
    border: none;
    background-color: transparent;
    margin: 20px 0 0 auto;
    cursor: pointer;
    font-size: 1.2em;
    font-weight: 600;
    transition: 200ms;
}

#contact button:hover {
    color: rgb(255, 100, 100);
    transform: scale(0.95);
    transition: 200ms;
}

#loading,
#success,
#error {
    margin: 20px 0 0 auto;
    font-size: 1.2em;
    font-weight: 500;
}

#success {
    color: green;
    margin-right: auto;
    text-align: center;
}

#error {
    color: red;
}

@media screen and (max-width: 1000px) {
    #contact button:hover {
        color: black;
        transform: scale(1);
        text-shadow: none;
    }
}

@media screen and (max-width: 700px) {
    #contact {
        padding: 50px;
    }

    #contact form {
        width: 100%;
    }

    #contact button,
    #loading,
    #error {
        text-align: center;
    }

}

@media screen and (max-width: 500px) {
    #contact {
        padding: 50px 10% 50px 10%;
    }

    #contact form {
        background-color: transparent;
        padding: 0px;
        box-shadow: none;
    }

    #contact input,
    #contact textarea {

        border-radius: 10px;
        background-color: rgb(255, 255, 255);
        box-shadow:
        2.8px 2.8px 2.2px rgba(0, 0, 0, 0.014),
        6.7px 6.7px 5.3px rgba(0, 0, 0, 0.02),
        12.5px 12.5px 10px rgba(0, 0, 0, 0.025),
        22.3px 22.3px 17.9px rgba(0, 0, 0, 0.03),
        41.8px 41.8px 33.4px rgba(0, 0, 0, 0.036),
        100px 100px 80px rgba(0, 0, 0, 0.05)
      ;
    }

}
#privacy-policy  {
    padding: 100px 5% 50px 5%;
    text-align: center;
}

#privacy-policy h1 {
    font-size: 2.5em;
    color: rgb(255, 100, 100);
}

#privacy-policy-project-name {
    font-weight: 500;
}
#about {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 50px 15% 50px 15%;
    height: auto;
}

#about h2 {
    margin-bottom: 20px;
}

#about__text {
    margin-bottom: 50px;
}

#about li {
    font-size: 1.2em;
    list-style: none;
    margin-top: 20px;
    margin-bottom: 20px;
}

#about span {
    color: rgb(255, 100, 100);
}

#about__networks-links {
    margin-top: 50px;
}

#about__networks-links img {
    height: 50px;
    margin: 10px;
    cursor: pointer;
    transition: 200ms;
}

#about__networks-links img:hover {
    opacity: 0.8;
    transform: scale(0.95);
    transition: 200ms;
}

@media screen and (max-width: 1000px) {
    
    #about__networks-links img:hover {
        opacity: 1;
        transform: scale(1);
    }
    
}


@media screen and (max-width: 700px) {
    
    #about {
        padding: 50px 10% 50px 10%;
    }
    
}

@media screen and (max-width: 500px) {
    
    #about {
        padding: 50px 5% 50px 5%;
    }
    
}

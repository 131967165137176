header {
    position: fixed;
    width: 100%;
    height: 70px;
    background-color: white;
    font-weight: 600;
    z-index: 2;
    box-shadow:
        0px 1.4px 2.2px rgba(0, 0, 0, 0.02),
        0px 3.3px 5.3px rgba(0, 0, 0, 0.028),
        0px 6.3px 10px rgba(0, 0, 0, 0.035),
        0px 11.2px 17.9px rgba(0, 0, 0, 0.042),
        0px 20.9px 33.4px rgba(0, 0, 0, 0.05),
        0px 50px 80px rgba(0, 0, 0, 0.07);
}

#header-container {
    height: 100%;
    max-width: 1920px;
    margin: 0 auto 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#header_logo-container {
    display: flex;
    align-items: center;
    margin-left: 80px;
    cursor: pointer;
    transition: 200ms;
    height: 100%;
    text-decoration: none;
    color: black;
}

#header_logo {
    height: 70%;
    width: auto;
    margin-right: 10px;
}

#header_logo-container p {
    font-size: 1.5em;
}

#header_logo-container:hover {
    transform: scale(0.95);
    transition: 200ms;
}

nav ul {
    margin-right: 80px;
    list-style: none;
    display: flex;
}

nav button {
    border: none;
    background-color: transparent;
    margin-left: 50px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    transition: 200ms;
}

nav button:hover {
    color: rgb(255, 100, 100);
    transform: scale(0.95);
    transition: 200ms;
}

#menu-close,
#menu-open {
    display: none;
}

#menu-open {
    margin-right: 50px;
}

#menu-close {
    position: absolute;
    top: 20px;
    right: 20px;
}

#check {
    display: none;
}

nav label {
    width: 40px;
    height: 40px;
}

nav label img {
    height: 100%;
}

@media screen and (max-width: 1000px) {

    #header_logo-container {
        margin-left: 50px;
    }

    nav ul {
        display: block;
        position: fixed;
        top: 0;
        left: -100%;
        margin: 0;
        padding-top: 20px;
        background-color: white;
        z-index: 1;
        width: 100%;
        transition: all 0.3s ease;
        box-shadow:
        0px 1.4px 2.2px rgba(0, 0, 0, 0.02),
        0px 3.3px 5.3px rgba(0, 0, 0, 0.028),
        0px 6.3px 10px rgba(0, 0, 0, 0.035),
        0px 11.2px 17.9px rgba(0, 0, 0, 0.042),
        0px 20.9px 33.4px rgba(0, 0, 0, 0.05),
        0px 50px 80px rgba(0, 0, 0, 0.07);
    }

    nav li {
        display: flex;
        justify-content: center;
    }

    nav button {
        margin: 0;
        padding-bottom: 20px;
    }

    nav button:hover {
        color: black;
        transform: scale(1);
    }

    #menu-open {
        display: block;
    }

    #check:checked~#menu-open {
        display: none;
    }

    #check:checked~ul #menu-close {
        display: block;
    }

    #check:checked~ul {
        left: 0;
    }

    #header_logo-container:hover {
        transform: scale(1);
    }

}

@media screen and (max-width: 450px) {

    #header_logo-container {
        font-size: 0.8em;
    }

    #header__logo {
        height: 40px;
    }


    #header_title {
        display: none;

    }

}